import React from "react"

const Footer = () => (
  <footer
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
        maxWidth: '900px',
        textAlign: `center`,
      }}
    >
      <p>
        <em>Market Box is based in Chicago’s Woodlawn neighborhood and works across the South Side. We are a project of Ecosystems of Care, a 501(c)3 organization registered in the United States of America (EIN 87-1345182).</em>
      </p>
      <p><em>Photos by Davon Clark, Anwuli Anigbo, and Kristi Jan Hoover<br />Website by Bea Malsky</em></p>
    </div>
  </footer>
)

export default Footer
